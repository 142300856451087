<template>
  <v-navigation-drawer
    :absolute="$vuetify.breakpoint.smAndDown"
    :width="260"
    :class="{ 'overview-menu': true, 'not-mobile': !$vuetify.breakpoint.smAndDown }"
    :value="showMenu"
    :permanent="showMenu && !$vuetify.breakpoint.smAndDown"
    floating
    @input="event => $emit('close-menu', event)"
  >
    <template v-slot:default>
      <v-treeview
        :items="menuToShow"
        item-key="name"
        open-on-click
        :active-class="setClass()"
        :open="openNodes"
        activatable
        color="inherit"
        @update:active="setRoute($event)"
        :active="activeNodes"
        return-object
      >
        <template slot="label" slot-scope="{ item }">
          <LinkButton 
            :text="traduceItem(item.name)"
            :to="!item.children ? { name: item.name } : {}"
            :active="active.includes(item.name)"
            :color="$vuetify.theme.dark ? 'var(--fontColor)' : 'var(--fontColorTerciary)'"
            :style="item.children ? {backgroundColor: 'var(--menuGray)'} : {}"
            :primaryColor="envTheme['--primary']"
          >
            <template v-slot:prev-content>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            </template>
          </LinkButton>
        </template>
      </v-treeview>

      <Actions
        :actions="actionButton"
        type="text-buttons"
        @action-selected="openExecuteProcessModal"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import i18n from "@/plugins/i18n";
import { tools } from "../mixins/tools";

export default {
  name: 'Menu',
  props: {
    showMenu: { type: Boolean, default: true },
  },
  data: () => ({
    active: [],
    menuToShow: [],
    openNodes: [],
    setClassItem: true
  }),
  mixins: [tools],
  computed: {
    ...mapState(["userLogged", "actualView", "appMenu", "envTheme"]),
    activeNodes () {
      return this.active.map(n =>  n)
    },
    actionButton() {
      return [
        {
          action: 'executeProccess',
          label: i18n.t('executeProcess'),
          icon: 'mdi-play',
          depressed: true,
          color: 'var(--secondary)',
          textColor: 'var(--white)',
          disabledTooltip: !this.$vuetify.breakpoint.smAndDown
        }
      ]
    }
  },
  watch: {
    showMenu(value) {
      this.setMenuOpen(value);
    },
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.active = this.$router.history.current.matched.map(item => (item.meta && item.meta.parent) || item.name || item.meta.name).filter(name => name);
      },
    }
  },
  methods: {
    ...mapActions(["setMenuOpen", "setIsExecuteProcessModalOpen"]),
    setRoute(event) {
      if (event && event.length && event[0].openNewWindow) this.setClassItem = false;
      else this.setClassItem = true;
      if (event.length && this.$route.name !== event[0].link)
        this.$router.push({ name: event[0].link }, () => {}, () => {});
    },
    setClass() {
      return this.setClassItem ? 'node-active' : '';
    },
    setOpenNodes(item) {
      if (item.parent) {
        this.setOpenNodes(item.parent);
      } else {
        this.openNodes.push({ name: item.name });
      }
    },
    createMenu(list) {
      list.forEach(element => {
        if (element.tabs && element.children)
          delete element.children;
        if (element.children)
          this.createMenu(element.children);
      });
    },
    async openExecuteProcessModal() {
      this.setIsExecuteProcessModalOpen(true);
    }
  },
  created() {
    this.menuToShow = this.appMenu.filter(item => !('hideInMenu' in item) || ('hideInMenu' in item && !item.hideInMenu));
    this.createMenu(this.menuToShow);
    this.setOpenNodes(this.actualView);
  },
  mounted() {
    this.setMenuOpen(this.showMenu);
  }
}
</script>

<style scoped lang="scss">
.overview-menu {
  height: 100vh;
  border-radius: 0 20px 20px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  min-width: 260px;
  z-index: 5;

  &.not-mobile {
    position: fixed;
    left: 0;
    top: 64px !important;
    height: calc(100vh - 64px) !important;
  
    &.v-navigation-drawer--close {
      width: 0 !important;
      min-width: 0 !important;
      transform: translateX(0%) !important;
    }
  }

  ::v-deep {
    .v-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__level {
      width: 0;
    }
    .v-treeview-node__root {
      padding: 0px;
      .v-treeview-node__content {
        margin: 0;
        color: var(--primary);
      }
    }
    .v-treeview-node__toggle {
      position: absolute;
      right: 15px;
      transform: rotate(0deg);
      &.v-treeview-node__toggle--open {
        transform: rotate(180deg);
      }
    }
  }
  ::v-deep .node-active::before {
    background-color: currentColor;
    opacity: .12;
  }
}

.close-icon {
  border: 2px solid var(--darkGray);
  &.v-btn--icon.v-size--default .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }
}

.actions-wrp {
  position: absolute;
  bottom: 20px;
  left: 20px;
  ::v-deep.action-text-btn-wrp {
    .action-text-btn {
      font-size: 16px;
      height: 58px;
      .v-icon.v-icon--left {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }
}
</style>