export default {
  "user": "Usuario",
  "password": "Contraseña",
  "loginUserError": "Usuario incorrecto",
  "loginPswdError": "Contraseña incorrecta",
  "loginEmailError": "Email incorrecto",
  "login": "Entrar",
  "loginWithGoogle": "Iniciar sesión con Google",
  "required": "Este campo es requerido",
  "internalServerError":
    "Error interno del servidor. Contacta con tu administrador",
  "updateAvailable": 'Hay una actualización disponible',
  "cancel": "Cancelar",
  "clone": "Clonar",
  "clon": "Clon de {element}",
  "save": "Guardar",
  "add": "Añadir",
  "edit": "Editar",
  "delete": "Eliminar",
  "deleteAll": "Eliminar todo",
  "update": "Actualizar",
  "more": "Más",
  "next": "Siguiente",
  "back": "Atrás",
  "filters": "Filtros",
  "columns": "Columnas",
  "importColumns": "Importar columnas",
  "loggedOut": "Has cerrado sesión correctamente",
  "clickToGoLogin": "Ir al login",
  "goBack": "Volver",
  "goHome": "Ir al inicio",
  "close": "Cerrar",
  "logout": "Cerrar sesión",
  "userConfiguration": "Configuración de usuario",
  "email": "Email",
  "ok": "Sí",
  "openMenu": "Abrir menú",
  "closeMenu": "Cerrar menú",
  "deleteLists": "Eliminar esta lista",
  "deleteListsQuestion": "¿Estás seguro de eliminar esta lista?",
  "deleteDictionaries": "Eliminar este diccionario",
  "deleteDictionariesQuestion": "¿Estás seguro de eliminar este diccionario?",
  "deleteInputDefinitions": "Eliminar esta definición de entrada",
  "deleteInputDefinitionsQuestion": "¿Estás seguro de eliminar esta definición de entrada?",
  "deleteOutputDefinitions": "Eliminar esta definición de salida",
  "deleteOutputDefinitionsQuestion": "¿Estás seguro de eliminar esta definición de salida?",
  "deleteInputDefinitionsDetailComponent": "Eliminar esta columna",
  "deleteInputDefinitionsDetailComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deleteOutputDefinitionsDetailComponent": "Borrar esta columna",
  "deleteOutputDefinitionsDetailComponentQuestion": "¿Estas seguro de eliminar esta columna?",
  "deleteInputDefinitionsDetail": "Eliminar esta definición de entrada",
  "deleteInputDefinitionsDetailQuestion": "¿Estás seguro de eliminar esta definición de entrada?",
  "deleteOutputDefinitionsDetail": "Eliminar esta definición de salida",
  "deleteOutputDefinitionsDetailQuestion": "¿Estás seguro de eliminar esta definición de salida?",
  "deletecolumnsInComponent": "Eliminar columna",
  "deletecolumnsInComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deletecolumnsOutComponent": "Eliminar columna",
  "deletecolumnsOutComponentQuestion": "¿Estás seguro de eliminar esta columna?",
  "deletefiltersComponent": "Eliminar filtro",
  "deletefiltersComponentQuestion": "¿Estas seguro de eliminar este filtro?",
  "deletedeleteSplitConfigurationComponent": "Eliminar toda la configuración",
  "deletedeleteSplitConfigurationComponentQuestion": "¿Estás seguro de que deseas eliminar toda la configuración?",
  "deletedeleteRowComponent": "Eliminar fila",
  "deletedeleteRowComponentQuestion": "¿Estás seguro de que deseas eliminar esta fila?",
  "deletedeleteHeaderCellComponent": "Eliminar columna",
  "deletedeleteHeaderCellComponentQuestion": "¿Estás seguro de que deseas eliminar esta columna?",
  "deletedeleteColumnComponent": "Eliminar columna",
  "deletedeleteColumnComponentQuestion": "¿Estás seguro de que deseas eliminar esta columna?",
  "deleteProcessHistory": "Eliminar proceso",
  "deleteProcessHistoryQuestion": "¿Estas seguro de eliminar este proceso?",
  "cloneInputDefinitions": "Clonar definicion de entrada",
  "cloneOutputDefinitions": "Clonar definicion de salida",
  "cloneDictionary": "Clonar diccionario",
  // MENU
  "Home": "Inicio",
  "ProcessHistory": "Histórico de procesos",
  "InputDefinitions": "Definiciones de entrada",
  "InputDefinitionsDetail": "Definiciones de entrada",
  "OutputDefinitions": "Definiciones de salida",
  "Dictionaries": "Diccionarios",
  "Lists": "Listas",
  "error": {
    "sorry": "Lo sentimos",
    "notAllowed": "este usuario no está permitido",
    "notFound": "esta página no existe",
    "noWorries": "Pero no te preocupes, siempre puedes",
    "contactAdmin": "Por favor, contacta con tu administrador",
    "problem": "hubo un problema...",
    "canNotTellWhy": "y no puedo decirte porqué...",
    "emailBlocked": "Este usuario no está autorizado. Solo pueden acceder usuarios con una cuenta de correo corporativa de NFQ",
    "add": {
      "Dictionaries": "No se ha podido añadir el diccionario {element}",
      "Lists": "No se ha podido añadir la lista {element}",
      "InputDefinitions": "No se ha podido añadir la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido añadir la definición de salida {element}",
      "InputDefinitionsDetail": "No se ha podido añadir la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido añadir la definición de salida {element}",
      "ProcessHistory": "No se ha podido ejecutar el proceso",
      "DictionariesDetail": "No se ha podido añadir el diccionario {element}",
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "No se ha podido añadir el valor"
    },
    "editCell": {
      "OutputDefinitionsDetail": "No se ha podido modificar el valor"
    },
    "edit": {
      "Dictionaries": "No se ha podido modificar el diccionario {element}",
      "Lists": "No se ha podido modificar la lista {element}",
      "InputDefinitions": "No se ha podido modificar la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido modificar la definición de salida {element}"
    },
    "delete": {
      "Dictionaries": "No se ha podido eliminar el diccionario {element}",
      "Lists": "No se ha podido eliminar la lista {element}",
      "InputDefinitions": "No se ha podido eliminar la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido eliminar la definición de salida {element}",
      "InputDefinitionsDetail": "No se ha podido eliminar la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido eliminar la definición de salida {element}",
      "DictionariesDetail": "No se ha podido eliminar el diccionario {element}",
      "allProcess": "No se ha podido eliminar la lista de procesos"
    },
    "clone": {
      "InputDefinitions": "No se ha podido clonar la definición de entrada {element}",
      "OutputDefinitions": "No se ha podido clonar la definición de entrada {element}",
      "Dictionary": "No se ha podido clonar el diccionario {element}"
    },
    "addColumn": {
      "InputDefinitionsDetail": "No se ha podido añadir la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido añadir la columna {element}"
    },
    "addColumnOut": {
      "OutputDefinitionsDetail": "No se ha podido añadir la columna {element}"
    },
    "editColumn": {
      "InputDefinitionsDetail": "No se ha podido modificar la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido modificar la columna {element}"
    },
    "editColumnOut": {
      "OutputDefinitionsDetail": "No se ha podido modificar la columna {element}"
    },
    "editParent": {
      "InputDefinitionsDetail": "No se ha podido modificar la definición de entrada {element}",
      "OutputDefinitionsDetail": "No se ha podido modificar la definición de salida {element}",
      "DictionariesDetail": "No se ha podido modificar el diccionario {element}"
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "No se ha podido eliminar la columna {element}",
      "OutputDefinitionsDetail": "No se ha podido eliminar la columna {element}"
    },
    "dragging": {
      "OutputDefinitionsDetail": "No se ha podido modificar la posición de la columna {element}"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "No se ha(n) podido añadir la(s) columna(s)"
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "No se ha podido modificar la columna {element}",
    },
    "addRow": {
      "OutputDefinitionsDetail": "No se ha podido añadir la fila"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "No se ha podido eliminar la columna {element}",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "No se ha podido eliminar la fila",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "No se ha podido eliminar toda la configuración",
    },
  },
  "noData": {
    "Dictionaries": "No hay diccionarios. Por favor, recuerda importar o crear un diccionario",
    "Lists": "No hay listas. Por favor, recuerda importar o crear una lista",
    "InputDefinitions": "No hay definiciones de entrada. Por favor, recuerda crear una definición de entrada",
    "OutputDefinitions": "No hay definiciones de salida. Por favor, recuerda crear una definición de salida",
    "InputDefinitionsDetail": "No hay columnas. Por favor, recuerda crear una columna",
    "OutputDefinitionsDetail": "No hay columnas. Por favor, recuerda crear una columna",
    "ProcessHistory": "No hay procesos ejecutados. Por favor, recuerda ejecutar un proceso",
    "allowedValuesValidation": {
      "lists": "No hay listas creadas",
      "dictionaries": "No hay diccionarios creados"
    },
    "columnsIn": "No hay columnas. Por favor, recuerda crear una columna",
    "columnsOut": "No hay columnas. Por favor, recuerda crear una columna",
    "filters": "No hay filtros. Por favor, recuerda crear un filtro",
    "splitConfiguration": "No hay separación de filas. Por favor, recuerda crear una columna primero para poder separar filas",
    "filtersNoActions": "No hay filtros. Por favor, recuerda crear una columna primero para poder crear un filtro",
    "DictionariesDetail": "El diccionario no está configurado. Haz click en Editar para configurarlo",
  },
  "searchLabel": {
    "Dictionaries": "Buscar diccionario",
    "Lists": "Buscar lista",
    "InputDefinitions": "Buscar definición de entrada",
    "ProcessHistory": "Buscar proceso",
    "OutputDefinitions": "Buscar definición de salida",
    "InputDefinitionsDetail": "Buscar definición de columna",
    "OutputDefinitionsDetail": "Buscar definición de columna",
  },
  "success": {
    "add": {
      "Dictionaries": "Se ha añadido el diccionario {element} correctamente",
      "Lists": "Se ha añadido la lista {element} correctamente",
      "InputDefinitions": "Se ha añadido la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha añadido la definición de salida {element} correctamente",
      "InputDefinitionsDetail": "Se ha añadido la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha añadido la definición de salida {element} correctamente",
      "ProcessHistory": "El proceso se ha iniciado correctamente. Accede a la pantalla Histórico de procesos para descargar el fichero",
      "DictionariesDetail": "Se ha añadido el diccionario {element} correctamente",
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "Se ha añadido el valor correctamente"
    },
    "editCell": {
      "OutputDefinitionsDetail": "Se ha modificado el valor correctamente"
    },
    "edit": {
      "Dictionaries": "Se ha modificado el diccionario {element} correctamente",
      "Lists": "Se ha modificado la lista {element} correctamente",
      "InputDefinitions": "Se ha modificado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha modificado la definición de salida {element} correctamente",
      "ProcessHistory": "El proceso se ha iniciado correctamente. Accede a la pantalla Histórico de procesos para descargar el fichero",
      "DictionariesDetail": "Se ha modificado el diccionario {element} correctamente",
    },
    "delete": {
      "Dictionaries": "Se ha eliminado el diccionario {element} correctamente",
      "Lists": "Se ha eliminado la lista {element} correctamente",
      "InputDefinitions": "Se ha eliminado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha eliminado la definición de salida {element} correctamente",
      "InputDefinitionsDetail": "Se ha eliminado la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha eliminado la definición de salida {element} correctamente",
      "DictionariesDetail": "Se ha eliminado el diccionario {element} correctamente",
      "process": "Se ha eliminado el proceso correctamente",
      "allProcess": "Se han eliminado todos los procesos correctamente"
    },
    "clone": {
      "InputDefinitions": "Se ha clonado la definición de entrada {element} correctamente",
      "OutputDefinitions": "Se ha clonado la definición de salida {element} correctamente",
      "Dictionary": "Se ha clonado el diccionario {element} correctamente"
    },
    "addColumn": {
      "InputDefinitionsDetail": "Se ha añadido la columna {element} correctamente",
      "OutputDefinitionsDetail": "Se ha añadido la columna {element} correctamente"
    },
    "addColumnOut": {
      "OutputDefinitionsDetail": "Se ha añadido la columna {element} correctamente"
    },
    "addFilter": {
      "InputDefinitionsDetail": "Se ha añadido el filtro {element} correctamente",
    },
    "editColumn": {
      "InputDefinitionsDetail": "Se ha modificado la columna {element} correctamente",
      "OutputDefinitionsDetail": "Se ha modificado la columna {element} correctamente"
    },
    "editColumnOut": {
      "OutputDefinitionsDetail": "Se ha modificado la columna {element} correctamente"
    },
    "editFilter": {
      "InputDefinitionsDetail": "Se ha modificado el filtro {element} correctamente",
    },
    "editParent": {
      "InputDefinitionsDetail": "Se ha modificado la definición de entrada {element} correctamente",
      "OutputDefinitionsDetail": "Se ha modificado la definición de salida {element} correctamente",
      "DictionariesDetail": "Se ha modificado el diccionario {element} correctamente",
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "Se ha eliminado {element} correctamente",
      "OutputDefinitionsDetail": "Se ha eliminado {element} correctamente"
    },
    "dragging": {
      "OutputDefinitionsDetail": "Se ha modificado la posición de la columna {element} correctamente"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "Se ha(n) añadido la(s) columna(s) correctamente",
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "Se ha modificado la columna {element} correctamente",
    },
    "addRow": {
      "OutputDefinitionsDetail": "Se ha añadido la fila correctamente"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "Se ha eliminado la columna {element} correctamente",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "Se ha eliminado la fila correctamente",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "Se ha eliminado toda la configuración correctamente",
    },
    "editDictionary": {
      "Dictionaries": "Se ha modificado el diccionario {element} correctamente",
      "DictionariesDetail": "Se ha modificado el diccionario {element} correctamente",
    },
    "addKeyValue": {
      "DictionariesDetail": "Se ha añadido la clave-valor correctamente",
    },
    "deleteKeyValue": {
      "DictionariesDetail": "Se ha eliminado la clave-valor correctamente",
    },
    "updateKeyValue": {
      "DictionariesDetail": "Se ha modificado la clave-valor correctamente",
    },
  },
  "addDictionary": "Añadir diccionario",
  "addList": "Añadir lista",
  "editList": "Editar lista",
  "editDictionary": "Editar diccionario",
  "addInputDefinition": "Añadir definición",
  "addOutputDefinition": "Añadir definición",
  "editInputDefinitions": "Editar definición de entrada",
  "addInputDefinitions": "Añadir definición de entrada",
  "addOutputDefinitions": "Añadir definición de salida",
  "editOutputDefinitions": "Editar definición de salida",
  "name": "Nombre",
  "selectAll": "Seleccionar todos",
  "unselectAll": "Deseleccionar todos",
  "noSearchData": "No hay resultados que coincidan con tu búsqueda",
  "discardChangesTitle": "Descartar cambios",
  "discardChangesText": "¿Estás seguro de que quieres descartar los cambios?",
  "fieldRequired": "Este campo es requerido",
  "all": 'Todos',
  "nameAlreadyExists": 'Este nombre está repetido',
  "hasBeenProblemTryAgain": 'Ha habido un problema y no podemos mostrarte los datos. Inténtalo de nuevo más tarde',
  "unexpectedError": "Lo sentimos, ha ocurrido un error inesperado. Si persiste, contacta con tu administrador",
  "resultsFound": "Resultados encontrados",
  "expiredSession": "Tu sesión ha caducado. Por favor, vuelve a iniciar sesión",
  "alertNoChanges": "Tienes que realizar algún cambio antes de guardar",
  "executeProcess": "Ejecutar proceso",
  "trafis-ui": "NFQ Trafis",
  "key": "Clave",
  "value": "Valor",
  "valueRepeated": "Este valor está repetido",
  "keyRepeated": "Esta clave está repetida",
  "columnRepeated": "Esta columna está repetida",
  "status": "Estado",
  "date": "Fecha",
  "userImage": "Usuario",
  "startTime": "Hora de inicio",
  "endTime": "Hora de fin",
  "inputFileName": "Nombre fichero de entrada",
  "delimiterColumns": "Separador de columnas",
  "processId": "Id del proceso",
  "inputDefinitionName": "Definición de entrada",
  "outputDefinitionNames": "Definiciones de salida",
  "download-file": "Descargar fichero",
  "download-validations": " Descargar validaciones",
  "downloadExample": "Descargar ejemplo",
  "fileType": "Tipo de archivo",
  "headerRow": "Fila de la cabecera",
  "headerRowTooltip": "Si no informas este campo, se asumirá que los datos empiezan en la primera fila del fichero",
  "optionalParameters": "Parámetros opcionales",
  "defaultDatePattern": "Formato de fecha",
  "defaultDatePatternTooltip": "Si no informas este campo en una o varias columnas de tipo fecha, se asumirá el formato por defecto para poder leerlas",
  "defaultDecimalSeparator": "Separador decimal",
  "defaultDecimalSeparatorTooltip": "Si no informas este campo en una o varias columnas de tipo número, se asumirá el separador decimal por defecto para poder leerlas",
  "comma": "Coma",
  "dot": "Punto",
  "fileName": "Nombre del archivo",
  "alias": "Nombre de entrada",
  "columnReferenceColumnName": "Nombre de entrada",
  "position": "Posición",
  "dataType": "Tipo de dato",
  "nullable": "Acepta valores nulos",
  "COMMA": "Coma",
  "DOT": "Punto",
  "EMPTY": "Vacío",
  "NOT_EMPTY": "No vacío",
  "REGULAR_EXPRESSION": "Expresión regular",
  "columnsDefinitions": "Definición de columnas",
  "filtersDefinitions": "Definición de filtros",
  "addColumn": "Añadir columna",
  "addColumnOut": "Añadir columna de salida",
  "addColumns": "Añadir columnas",
  "addFilter": "Añadir filtro",
  "positionTooltip": "Si no informas este campo, la posisición se rellenará automaticamente con su posición en la lista",
  "sumValues": "Obtener valor acumulado",
  "optionalValidations": "Validaciones opcionales",
  "outputAlias": "Nombre de salida",
  "transformationType": "Tipo de transformación",
  "OutputDefinitionsDetail": "Definición de salida",
  "DictionariesDetail": "Diccionarios",
  "datePattern": "Formato de fecha",
  "datePatternTooltip": "Este campo es obligatorio si seleccionas el tipo de dato Date y siempre que no hayas informado el valor por defecto al crear la definición",
  "decimalSeparator": "Separador decimal",
  "decimalSeparatorTooltip": "Este campo es obligatorio si seleccionas el tipo de dato Double o Percentage y siempre que no informes el valor por defecto al crear la definición",
  "sumValuesTooltip": "Si activas este switch, se realizará un acumulado de las filas válidas y no válidas de esta columna",
  "positionAlreadyExists": "La posición elegida ya está en uso",
  "editColumn": "Editar columna",
  "editColumnOut": "Editar columna de salida",
  "editFilter": "Editar filtro",
  "conditions": "Condiciones",
  "condition": "Condición",
  "selectedFilterNames": "Filtros",
  "selectFilters": "Selecciona, si quieres, uno o varios filtros",
  "definitionHasNoFilters": "Esta definición no tiene filtros asociados",
  "validationOptionalType": "Tipo de validación opcional",
  "allowedValuesValidation": "Valores permitidos",
  "range": "Rango",
  "from": "Desde",
  "to": "Hasta",
  "rangeType": "Tipo de rango",
  "OPEN": "Abierto",
  "CLOSE": "Cerrado",
  "OPEN_CLOSE": "Abierto - Cerrado",
  "CLOSE_OPEN": "Cerrado - Abierto",
  "allowedValuesValidationTooltip": "En este campo puedes asociar un diccionario o una lista de valores para validar que tus valores pertenecen a ese diccionario (claves) o esa lista",
  "runProcess": "Ejecutar proceso",
  "selectFileToTransform": "Selecciona el fichero que deseas transformar",
  "selectFile":"Selecciona el fichero",
  "yourPc": "Tu PC",
  "googleDrive": "Google Drive",
  "selectInputDefinition": "Selecciona una definición de entrada",
  "selectedInputDefinition": "Definición de entrada seleccionada",
  "selectOneOrMoreOutputDefinition": "Selecciona una o varias definiciones de salida",
  "youMustSelectAnImportOption": "Debes seleccionar una opción de importación",
  "importFileFromYourPc": "Importar fichero desde tu PC",
  "clickOrDragToImport": "Haz click o arrastra para importar",
  "youMustSelectAtLeastOneFileToImport": "Debes seleccionar al menos un fichero para importar",
  "inputDefinitions": "Definición de entrada",
  "outputDefinitions": "Definiciones de salida",
  "splitConfiguration": "Separación filas",
  "splitConfigurationDefinitions": "Separación de filas",
  "selectOutputDefinitions": "Selecciona una o varias definiciones de salida solo si deseas transformar el fichero",
  "noDataAutocomplete": "No hay datos disponibles",
  "extensionErrorFile": "La extensión de este fichero no es compatible. Debes seleccionar un fichero .{element}",
  "runningProcess": "El proceso se está ejecutando",
  "seeProcessStatus": "Accede a la pantalla Histórico de procesos para conocer su estado",
  "seeProcesses": "Accede a la pantalla Histórico de procesos para descargar el fichero",
  "addTenor": "Añadir tenor",
  "dictionary": "Diccionario",
  "dateColumnName": "Columna fecha",
  "timeStepColumnName": "Columna tenor",
  "tenor": "Tenor",
  "limitDate": "Fecha límite",
  "operator": "Operador",
  "aliasTooltip": "Este campo es obligatorio si quieres modificar el alias de entrada",
  "decimalSeparatorTooltipOutput": "Este campo es obligatorio si quieres modificar el separador decimal",
  "datePatternTooltipOutput": "Este campo es obligatorio si quieres modificar el formato de fecha",
  "limitDateTooltipOutput": "Si informas este campo, se sumará el tenor al valor de tu columna hasta que se alcance la fecha límite",
  "dictionaryName": "Nombre del diccionario",
  "finishProcess": "El proceso se ha ejecutado correctamente",
  "seeFileProcesses": "Accede a la pantalla Histórico de procesos para descargar el fichero",
  "INTEGER": "Integer",
  "DOUBLE": "Double",
  "DATE": "Date",
  "STRING": "String",
  "BOOLEAN": "Boolean",
  "PERCENTAGE": "Percentage",
  "MAIL": "Mail",
  "noDataInfoColumn": "No hay más información sobre esta columna",
  "rangeValidation": "Validación de rango",
  "validationOfAllowedValues": "Validación de valores permitidos",
  "parametersTransfDictionary": "Parámetros de la transformación de diccionario",
  "parametersTransfAddTenor": "Parámetros de la transformación añadir tenor",
  "timeStepValue": "Tenor",
  "arithmeticOperator": "Operador",
  "ADD": "+",
  "SUBTRACT": "-",
  "DICTIONARY": "Diccionario",
  "ADD_TIME_STEP": "Añadir tenor",
  "TENOR": "Tenor",
  "continue": "Continuar",
  "execute": "Ejecutar",
  "errorExecuteProcess": "No se ha podido ejecutar el proceso.",
  "modifyPosition": "Modificar posición",
  "keepPosition": "Mantener posición",
  "disabledDragTooltip": "Puedes modificar la posición haciendo click en el botón 'Modificar posición'",
  "disabledDragSearchingTooltip": "Esta acción no está disponible cuando aplicas filtros en el buscador",
  "disabledExpandTooltip": "Esta acción no está disponible cuando modificas la posición",
  "yes": "Sí",
  "no": "No",
  "valueGreaterThanZero": "El valor debe ser mayor que 0",
  "valueCombinationRepeated": "Esta combinación de alias de entrada y alias de salida ya existe",
  "addFilters": "Añadir filtro",
  "editFilters": "Editar filtro",
  "indicateFilterName": "Indica el nombre del filtro",
  "filterName": "Nombre del filtro",
  "defineConditions": " Define sus condiciones",
  "firstTerm": "Indica el primer término",
  "indicateConditional": "Indica el condicional",
  "conditional": "Condicional",
  "secondTerm": "Indica el segundo término",
  "columnAlias": "Alias de la columna",
  "indicateConditionalNotOk": "Indica el valor si la condición no se cumple",
  "reviewValidationsAttr": "Revisa las validaciones del formulario para poder añadir {attr}",
  "alertEditDictionary": "Antes de modificar el diccionario, recuerda que puede estar asociado a alguna definición de entrada",
  "theFilter": "el filtro",
  "theColumn": "la columna",
  "theValue": "el valor",
  "indicateColumnName": "Indica el nombre de la columna",
  "nameColumn": "Nombre de la columna",
  "defineCreateColumn": "Define cómo crear la columna",
  "defineCreateValue": "Define cómo crear el valor",
  "withOutConditions": "Sin condiciones",
  "withConditions": "Con condiciones",
  "createColumn": "Crear columna",
  "defineHowSetValue": "Define cómo asignar el valor",
  "assignValue": "Asigna valor",
  "assignValueToElement": "@:assignValue {element}",
  "concatenateValues": "Concatenando valores",
  "defineCases": "Define los casos",
  "inputDefinitionAssociate": "Asocia, si quieres, una definición de entrada",
  "inputDescriptorReferenceTooltip": "Si asocias una definición de entrada te será más fácil crear las columnas de salida",
  "deleteAllProcess": "Eliminar procesos",
  "deleteAllProcessTitle": "Eliminar todos los procesos",
  "deleteAllProcessQuestion": "¿Estás seguro de eliminar todos los procesos? Los procesos que se están ejecutando no se eliminarán",
  "deleteProcessTitle": "Eliminar proceso",
  "deleteProcessQuestion": "¿Estás seguro de eliminar este proceso?",
  "downloadingFile": "El fichero se está descargando",
  "errorDownloadFile": "No se ha podido descargar el fichero",
  "inputColumnName": "Nombre columna de entrada",
  "tenorColumnName": "Nombre de una columna tipo Tenor",
  "tenorInputValue": "Valor fijo para el Tenor",
  "indicateDateToReach": "Indica, si quieres, la fecha que deseas alcanzar",
  "limitDateColumnName": "Nombre de una columna tipo Fecha",
  "limitDateInputValue": "Valor fijo para la Fecha",
  "columnReferenced": "Columna referenciada",
  "valuesToConcatenate": "Valores a concatenar",
  "caseConditions": "Condiciones caso",
  "firstTermField": "Primer término",
  "secondTermField": "Segundo término",
  "assignValueFirstTerm":"Asigna valor al primer término",
  "assignValueSecondTerm":"Asigna valor al segundo término",
  "case": "Caso",
  "notConditionsApllied": "Sin condiciones aplicadas",
  "alertInfoConditionsEmpty": "Si no añades ninguna condición, este caso se aplicará cuando las condiciones no se cumplan",
  "alertErrorConditionsEmpty": "No puedes añadir más de un caso sin condiciones aplicadas",
  "eachCase": "Con condiciones",
  "addTimeStepTransformation": "Añadir tenor",
  "dictionaryTransformation": "Diccionario",
  "joinColumns": "Valores concatenados",
  "INPUT_VALUE": "Valor fijo",
  "COLUMN_NAME": "Referenciando una columna",
  "INPUT_COLUMN_NAME": "Referenciando una columna de entrada",
  "OUTPUT_COLUMN_NAME": "Referenciando una columna de salida",
  "WILDCARD": "Wildcard",
  "assignedValue": "Valor asignado",
  "assignedValueBy": "Valor asignado mediante",
  "tenorValue": " para el tenor",
  "targetDate": "Fecha que deseas alcanzar",
  "columnsIn": "Buscar definición de columna",
  "columnsOut": "Buscar definición de columna",
  "noConditionsApplied": "Sin condiciones aplicadas",
  "inputDescriptorReference": "Definición de entrada",
  "conditionals": "Condicionales",
  "selectImportFile": "Elige el tipo de fichero que vas a importar",
  "successfulImport": "El fichero {element} se ha importado correctamente",
  "uploadedImport": "El fichero {element} se está importando",
  "sheetName": "Nombre pestaña documento xlsx",
  "columnsToRead": "Indica el nombre de las columnas que se deben leer",
  "inputAlias": "Columna Nombre de entrada",
  "positionColumn": "Columna Posición",
  "mandatoryColumn": "Columna Acepta valores nulos",
  "dataTypeColumn": "Columna Tipo de dato",
  "outputColumnName": "Columna Nombre de salida",
  "showFinalReportColumn": "Columna Mostrar en el informe final",
  "filter": "Filtro",
  "selectFileToImport": "Selecciona el fichero a importar",
  "import": "Importar",
  "addRow": "Añadir fila",
  "rowColumnInformation": "Información Fila {index} - {column}",
  "valueAssigned": "Valor asignado mediante {item}",
  "row": "Fila",
  "rows": "Filas",
  "addCellValue": "Añadir valor {element}",
  "defineHowToCreateValue": "Define como crear el valor",
  "createValue": "Crear valor",
  "editCellValue": "Editar valor {element}",
  "shouldBeGreatherThan": "El valor debe ser mayor que {element}",
  "withoutFilters": "Sin filtros",
  "withFilters": "Con filtros",
  "fixedValue": "Valor fijo",
  "wildcard": "Wildcard",
  "columnReference": "Referenciando una columna",
  "columnReferenceInput": "Referenciando una columna de entrada",
  "columnReferenceOutput": "Referenciando una columna de salida",
  "onlyAvailableOneOfValues": "Solo puedes modificar uno de los dos valores",
  "validationOf": "Validación de {validation}",
  "importDictionary": "Importar diccionario",
  "dictionaryDetail": "Detalle del diccionario",
  "addKeyValue": "Añadir clave-valor",
  "maximizeTable": "Maximizar tabla",
  "VALIDATION_DUPLICATED_KEYS_SNACKBAR": "Revisa las validaciones para poder añadir el conjunto de clave-valor",
  "VALIDATION_DUPLICATED_KEYS_TOOLTIP": "No puede haber 2 combinaciones de keys iguales",
  "restoreTable":"Restaurar tabla",
  "deleteDictionariesDetail":"Eliminar este diccionario",
  "deleteDictionariesDetailQuestion":"¿Estás seguro de eliminar este diccionario?",
  "deleteDictionaryAlert":"Este diccionario está asociado a una o varias columnas. Si lo eliminas, estas asociaciones desaparecerán",
  "invalidDate": "Fecha inválida",
  "columnSeparatorStep": "Indica el separador de columnas",
  "TAB": "Tabulación",
  "SPACE": "Espacio",
  "PIPE": "Barra vertical",
  "SEMICOLON": "Punto y coma",
  "delimiterLabel": "Separador de columnas",
  "inputDescriptorInfoLabel": "Definición de entrada",
  "or": "o",
  "applyVar": "Aplicar variable",
  "nameVar": "Nombre de la variable",
  "defineVariables": "Define las variables",
  "alertInfoInputVariablesArrayFormText": "Recuerda que si hay más de una variable con el mismo nombre, solo mostramos una porque se le asignará el mismo valor a todas",
  "noVariables": "No hay variables para definir",
  "definitions": "definiciones",
  "plusNElements": "(+{number} {element})",
  "indicateColumnsToCompare": "Indica las columnas con las que comparar las keys del diccionario",
  "dictionaryFormWarning": "Recuerda que el nombre que asignas a esta columna no es el que has indicado, sino el que corresponde a cada columna value del diccionario",
  "indicateDictionaryName": "Indica el nombre del diccionario",
  "defineDictionaryKeys": "Define los campos Key",
  "defineDictionaryValues": "Define los campos Value",
  "keyField": "Campo Key",
  "valueField": "Campo Value",
  "deleteKeyTitle": "Eliminar esta key",
  "deleteKeyQuestion": "¿Estás seguro de eliminar esta key?",
  "deleteKeyAlert": "Si eliminas esta key, sus valores asociados también se eliminarán",
  "deleteValueTitle": "Eliminar este value",
  "deleteValueQuestion": "¿Estás seguro de eliminar este value?",
  "deleteValueAlert": "Si eliminas este value, sus valores asociados también se eliminarán",
  "modifiedDictionaryAlert": "Recuerda modificar las columnas de salida que tengan asociadas este diccionario",
  "deleteDictionarySettingTitle": "Eliminar toda la configuración del diccionario",
  "deleteDictionarySettingQuestion": "¿Estás seguro de eliminar toda la configuración del diccionario?",
  "deleteDictionaryKeyValueTitle": "Eliminar esta clave-valor",
  "deleteDictionaryKeyValueQuestion": "¿Estás seguro de eliminar esta clave-valor?",
  "elementDoesntExistWarning": "Este elemento ya no existe",
  "withOrWithoutConditionsTitle": "Define cómo crear la columna",
  "showInFinalReport": "Mostrar en el informe final",
  "VARIABLE": "Aplicar variable",
  "appliedVar": "Variable aplicada",  
  "inputTextLabel": "Nombre",
  "startDate": "Fecha de inicio",
  "finalDate": "Fecha de fin",
  "finalDateOptional": "Fecha de fin, este valor no es obligatorio",
  "endDate": "Fecha de fin",
  "indicateVirtualColumn": "Indica si deseas mostrar esta columna en el informe final",
  "dateDecimalTitle": "Modifica, si quieres, el formato de fecha o el separador decimal",
  "referencedInputColumn": "Columna de entrada referenciada",
  "referencedOutputColumn": "Columna de salida referenciada",
  "noDataColumns": "No hay columnas",
  "variables": "Variables",
  "support": "Soporte",
  "noDataSelects": {
    "definitions": "No hay definiciones",
    "filters": "No hay filtros",
    "dictionaries": "No hay diccionarios",
    "cloud": "No hay ficheros"
  },
  "infoIn": "Información",
  "infoOut": "Información",
  "infoAlertFilters": "Recuerda que los filtros que apliques en la ejecución eliminarán las filas que cumplen las condiciones",
  "arithmeticTransformation": "Operación matemática",
  "inputColumnsRef": "${ } columnas de entrada",
  "outputColumnsRef": "$${ } columnas de salida",
  "operation": "Operación",
  "reviewMathOpInfo": "Revisa las instrucciones para generar operaciones matemáticas",
  "reviewMathOpInfoStep1": "Puedes referenciar <b>columnas de entrada</b> con <b>${columnaDeEntrada}</b>",
  "reviewMathOpInfoStep2": "Puedes referenciar <b>columnas de salida</b> con <b>$${columnaDeSalida}</b>",
  "reviewMathOpInfoStep3": "Los <b>operadores</b> que puedes utilizar son <b>[]() + - * /</b>",
  "reviewMathOpInfoStep4": "Puedes utilizar <b>cualquier valor numérico</b>",
  "reviewMathOpInfoExample": "(${ ce1 } + $${ cs3 })* 100",
  "errorFormatFormula": "La operación no está bien construida. Después de $ ó $$ debes utilizar {}",
  "example": "Ejemplo",
  "trafisCloud": "Nube de Trafis",
  "labelCloudSelectFile": "Ficheros Nube de Trafis",
  "noDataCloudFiles": "No hay fichero para seleccionar",
  "warningCloudFile": "Este fichero ya existe en la Nube de Trafis. Si continuas, reemplazarás el existente y el proceso tardará mas en ejecutarse",
  "noValueAssignedColumn": "Esta columna no tiene valor asignado",
  "substringTransformation": "Rango de caracteres",
  "indicateRange": "Indica el rango de caracteres para asignar el valor",
  "inputColumn": "Columna de entrada",
  "outputColumn": "Columna de salida",
  'referencedColumn': 'Columna referenciada',
  "homeText": "Todo lo que tus ficheros necesitan",
  "downloadAllExamples": "Descargar ejemplos",
  "beginWithTrafis": "Empieza a trabajar con Trafis",
  "lastProcess": "Últimos procesos",
  "consultLastProcess": "Consulta los últimos procesos",
  "usageGuide": "Guía de uso",
  "learnUseTrafis": "Aprende a utilizar Trafis",
  "trafisUserGuide": "Guía de uso de Trafis",
  "outPutColumnsWithOutConditions": "Columnas de salida sin condiciones",
  "outPutColumnsWithConditions": "Columnas de salida con condiciones",
  "dictionaries": "Diccionarios",
  "validateInputFile": "Validar fichero de entrada",
  "errorLoadVideo": "No se ha podido cargar el video",
  "downloadExamples": "Descargar ejemplos",
  "downloadExamplesText": "Descarga desde aquí los archivos .csv y .xlsx necesarios para poder trabajar y experimentar con la herramienta",
  "inputColumns": "Columnas de entrada",
  "outputColumns": "Columnas de salida",
  "userManual": "Manual de usuario",
  "checkManual": "Consulta el manual",
  "errorDownloadExamples": "No se han podido descargar los ejemplos",
  "unique.identifier" : "unique identifier",
  "identifier": "line counter"
};
