import { render, staticRenderFns } from "./DetailDictionary.vue?vue&type=template&id=6518ed04&scoped=true"
import script from "./DetailDictionary.vue?vue&type=script&lang=js"
export * from "./DetailDictionary.vue?vue&type=script&lang=js"
import style0 from "./DetailDictionary.vue?vue&type=style&index=0&id=6518ed04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6518ed04",
  null
  
)

export default component.exports