<template>
  <v-app class="nfq">
    <v-main>
      <div v-if="snackBar" class="snackBarContainer">
        <v-snackbar v-model="snackBar" :color="snackBar.color" :top="true">
          <div class="d-flex justify-center align-center">
            <v-icon color="white" v-if="snackBar.icon" left>{{
              snackBar.icon
            }}</v-icon>
            <span>
              {{ snackBar.msg }}
              <router-link
                v-if="snackBar.link"
                :to="{ name: snackBar.link.to }"
                >{{ snackBar.link.text }}</router-link
              >
            </span>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn class="btn-snack" icon @click="setShowSnackBar(false); setHttpStatus(false)">
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ traduceItem("close") }}</span>
          </v-tooltip>
        </v-snackbar>
      </div>

      <template v-if="swUpdateExists">
        <v-snackbar top :value="swUpdateExists" :timeout="-1" color="info">
          <div>
            <v-icon color="white" left>mdi-information</v-icon>
            <span>{{ traduceItem("updateAvailable") }}</span>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn icon color="white" @click="refreshApp">
                  <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ traduceItem("update") }}</span>
          </v-tooltip>
        </v-snackbar>
      </template>
      
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { theme } from "./mixins/theme.js";
import { mapState, mapActions } from "vuex";
import { updateSw } from "./mixins/update-sw.js";
import { firebaseTools } from "./mixins/firebase-tools.js";
import { tools } from "./mixins/tools.js";

export default {
  name: "App",
  mixins: [theme, updateSw, firebaseTools, tools],
  data: () => ({
    snackBar: undefined,
  }),
  computed: {
    ...mapState([
      "actualView",
      "showSnackBar",
      "swUpdateExists",
      "httpStatus",
      "routeVuex",
      "userLogged"
    ]),
  },
  watch: {
    httpStatus(val) {
      this.snackBar = val;
    },
    showSnackBar(val) {
      this.snackBar = val;
    },
    $route() {
      this.$root.$emit("triggerScroll");
    },
    routeVuex(value) {
      if (value) {
        this.navigateTo(value)
        this.setRouteVuex(undefined)
      }
    },
  },
  methods: {
    ...mapActions(["setShowSnackBar","setRouteVuex", "setHttpStatus"]),
  },
  created() {
    this.subscribeIsUserLogged();
    let mode = "light";
    // if (localStorage.getItem("appearanceMode")) {
    //   mode = localStorage.getItem("appearanceMode");
    // } else {
    //   mode = window.matchMedia("(prefers-color-scheme: dark)").matches
    //     ? "dark"
    //     : "light";
      localStorage.setItem("appearanceMode", mode);
    // }
    document.body.classList.toggle(`${mode}-mode`);
    let theme = "default";
    // if (mode === "dark" && localStorage.getItem("appearanceTheme")) {
    //   theme = localStorage.getItem("appearanceTheme");
    // }
    this.setTheme('trafis-ui');
    this.setVuetifyTheme(theme);
    localStorage.setItem("appearanceTheme", theme);
    document.body.classList.toggle(`${theme}-theme`);
    document.addEventListener("visibilitychange", ()=> {
      if (document.visibilityState === "visible") {
        this.checkSession();
      }
    });
  },
  beforeDestroy() {
    this.clearTimeOutCheckToken();
  },
  
};
</script>

<style lang="scss">
@import "./assets/styles.scss";
</style>
