<template>
  <div class="downloadCard">
    <section class="downloadCard__header">
      <div class="downloadCard__header__content">
        <v-icon
          class="downloadCard__header__content-icon"
          color="var(--primary)"
          >mdi-file-document</v-icon
        >
        <span class="downloadCard__header__content-title">{{
          $t("downloadExamples")
        }}</span>
      </div>
      <v-icon class="downloadCard__header__close-icon" @click="closeCard"
        >mdi-close</v-icon
      >
    </section>

    <div class="downloadCard__text">
      {{ $t("downloadExamplesText") }}
    </div>
    <div class="downloadCard__list-container">
      <div class="downloadCard__list-container__list-item" v-for="example of examples" :key="example">
        {{ $t(example) }}
      </div>
    </div>
    <div class="downloadCard__btn-container">
      <v-btn
        class="btn"
        @click="downloadExamples()"
        :loading="loading"
        rounded
        color="primary"
      >
        <v-icon left>mdi-cloud-download</v-icon>
        {{ $t("downloadExamples") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import { getDownloadURL } from "firebase/storage";
import { mapState, mapActions } from "vuex";

export default {
  name: "DownloadExamples",
  mixins: [tools, firebaseTools],
  data() {
    return {
      examples: [
        "inputColumns",
        "outputColumns",
        "dictionaries",
        "filters",
        "validateInputFile",
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState(["userLogged"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar"]),
    closeCard() {
      this.$emit("close");
    },
    downloadExamples() {
      this.loading = true;
      const { lang } = this.userLogged || {};
      const nameFile = "trafis_files_specifications.zip";
      const fileRef = this.createStorageReference(
        `specifications/${lang}/${nameFile}`
      );
      getDownloadURL(fileRef)
        .then(async (url) => {
          await this.downloadFile(url, "get", nameFile, null);
        })
        .catch(() => {
          this.setShowSnackBar({
            color: "error",
            msg: this.traduceItem("errorDownloadExamples"),
            icon: "mdi-alert-circle",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.downloadCard {
  width: 455px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
   // 300px viene del cálculo de la card general
  max-height: calc(100vh - 340px);

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__content {
      display: flex;
      align-items: center;
      

      &-icon {
        font-size: 24px;
      }

      &-title {
        font-size: 20px;
        margin-left: 10px;
      }
    }

    &__close-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }

  &__text {
  
    font-size: 16px;
    line-height: 18.75px;
    color: var(--fontColorTerciary);
  }



&__list-container{
  gap: 10px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto; 
  max-height: calc(430px - 70px);
  &__list-item {
    padding: 10px 0px;
    border-bottom: 1px solid var(--borderGray);

  }
}
  &__btn-container {
    margin-top: 15px;
    max-height: 44px;
    .btn {
      height: 44px;
      padding: 11px 0;
      font-size: 18px;
      font-weight: 400;
      width: 100%;
      box-shadow: none;
      letter-spacing: normal;
      .v-icon {
        font-size: 24px !important;
      }
    }
  }
}
</style>