import i18n from "@/plugins/i18n";

export default {
  computed: {
    columnsIn() {
      return {
        title: i18n.t("importColumns"),
        importUrl: "import/columns",
        attrToAppendData: "inputColumns",
        exampleFile: 'trafis_input_columns_specifications.xlsx',
        downloadButton: {
          icon: "mdi-cloud-download",
          text: i18n.t("downloadExample"),
        },
        steps: [
          {
            title: i18n.t("selectImportFile"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("fileType"),
                  value: "fileType",
                  lowerCase: true,
                },
                {
                  label: i18n.t("sheetName"),
                  value: "sheetName",
                  showOnlyIfHasValue: true,
                },
              ],
            },
            fields: [
              {
                name: "fileType",
                hideTitle: true,
                resetVars: true,
                fieldType: "radioGroup",
                dataSource: [
                  { value: "CSV", name: "csv" },
                  { value: "XLSX", name: "xlsx" },
                ],
                optionsToCheck: [
                  { field: "fileType", value: "CSV" },
                  { field: "fileType", value: "XLSX" },
                ],
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "sheetName",
                label: i18n.t("sheetName"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "fileType",
                      type: "radioGroup",
                      value: "XLSX",
                    },
                  ],
                },
              },
            ],
          },
          {
            title: i18n.t("columnsToRead"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("inputAlias"),
                  value: "alias",
                },
                {
                  label: i18n.t("positionColumn"),
                  value: "position",
                },
                {
                  label: i18n.t("mandatoryColumn"),
                  value: "nullable",
                },
                {
                  label: i18n.t("dataTypeColumn"),
                  value: "dataType",
                },
              ],
            },
            fields: [
              {
                name: "alias",
                label: i18n.t("inputAlias"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "position",
                label: i18n.t("positionColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "nullable",
                label: i18n.t("mandatoryColumn"),
                fieldType: "textBox",
              },
              {
                name: "dataType",
                label: i18n.t("dataTypeColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
            ],
          },
          {
            title: i18n.t("selectFileToImport"),
          },
        ],
      }
    },
    columnsOut() {
      return {
        title: i18n.t("importColumns"),
        importUrl: "import/outputColumns",
        attrToAppendData: "outputColumns",
        exampleFile: 'trafis_output_columns_specifications.xlsx',
        downloadButton: {
          icon: "mdi-cloud-download",
          text: i18n.t("downloadExample"),
        },
        steps: [
          {
            title: i18n.t("selectImportFile"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("fileType"),
                  value: "fileType",
                  lowerCase: true,
                },
                {
                  label: i18n.t("sheetName"),
                  value: "sheetName",
                  showOnlyIfHasValue: true,
                },
              ],
            },
            fields: [
              {
                name: "fileType",
                hideTitle: true,
                resetVars: true,
                fieldType: "radioGroup",
                dataSource: [
                  { value: "CSV", name: "csv" },
                  { value: "XLSX", name: "xlsx" },
                ],
                optionsToCheck: [
                  { field: "CSV", value: "CSV" },
                  { field: "XLSX", value: "XLSX" },
                ],
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "sheetName",
                label: i18n.t("sheetName"),
                fieldType: "textBox",
                validators: {
                  dependsOn: [
                    {
                      field: "fileType",
                      type: "radioGroup",
                      value: "XLSX",
                    },
                  ],
                },
              },
            ],
          },
          {
            title: i18n.t("columnsToRead"),
            resume: {
              from: "currentDataValues",
              fields: [
                {
                  label: i18n.t("positionColumn"),
                  value: "position",
                },
                {
                  label: i18n.t("inputAlias"),
                  value: "referencedInputColumn",
                },
                {
                  label: i18n.t("outputColumnName"),
                  value: "alias",
                },
                {
                  label: i18n.t("showFinalReportColumn"),
                  value: "showInFile",
                },
              ],
            },
            fields: [
              {
                name: "position",
                label: i18n.t("positionColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "referencedInputColumn",
                label: i18n.t("inputAlias"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "alias",
                label: i18n.t("outputColumnName"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
              {
                name: "showInFile",
                label: i18n.t("showFinalReportColumn"),
                fieldType: "textBox",
                validators: {
                  required: { msg: i18n.t("required") },
                },
              },
            ],
          },
          {
            title: i18n.t("selectFileToImport"),
          },
        ],
      }
    }
  },
};
