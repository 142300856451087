<template>
  <div class="usageGuide-card">
    <section class="usageGuide-card__header">
      <div class="usageGuide-card__header__before">
        <v-icon class="usageGuide-card__header__before__icon"   color="var(--primary)">mdi-video</v-icon>
        <span class="usageGuide-card__header__before__title">{{
          $t("trafisUserGuide")
        }}</span>
      </div>
      <v-icon
        class="usageGuide-card__header__closeIcon"
        @click="closeCard"
        >mdi-close</v-icon
      >
    </section>
    <div class="usageGuide-card__list">
      <div
        class="usageGuide-card__list__video-item"
        v-for="video of videosList"
        :key="video.label"
      >
        <span class="usageGuide-card__list__video-item__label">{{
          $t(video.label)
        }}</span>
        <v-icon
          class="usageGuide-card__list__video-item__playIcon"
          @click="playVideo(video)"
          color="var(--primary)"
          >mdi-play-circle</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageGuide",
  data() {
    return {
      videosList: [
        {
          label: "outPutColumnsWithOutConditions",
          file: "output_columns_without_conditions",
        },
        {
          label: "outPutColumnsWithConditions",
          file: "output_columns_with_conditions",
        },
        {
          label: "dictionaries",
          file: "dictionaries",
        },
        {
          label: "filters",
          file: "filters",
        },
        {
          label: "validateInputFile",
          file: "validate_input_file",
        },
      ],
    };
  },
  methods: {
    closeCard() {
      this.$emit("close");
    },
    playVideo(video) {
      this.$emit("showVideoPopUp", video);
    },
  },
};
</script>
<style lang="scss" scoped>
.usageGuide-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 455px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 24px;
    align-items: center;

    &__before {
      display: flex;
      gap: 8px;

      &__title {
        font-size: 20px;
        color: var(--fontColor);
      }
    }
    &__closeIcon {
      color: var(--fontColor);
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__video-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0px 10px 0px;
      border-bottom: 1px solid var(--borderGray);

      &__label {
        color: var(--fontColor);
      }
    }
  }
}
</style>
