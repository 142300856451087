<template>
  <div class="video-container">
    {{ $t(config.label) }}
    <video
      v-if="!loading"
      paused
      controls
      width="100%"
      height="auto"
      :src="url"
      type="video/mp4"
      poster="/img/bg_video_trafis.png"
    ></video>
    <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
    <v-btn class="video-container__button" @click="closePopUp()" outlined rounded>
      <v-icon left>mdi-close</v-icon>
      {{ $t("close") }}
    </v-btn>
  </div>
</template>

<script>
import { firebaseTools } from "@/mixins/firebase-tools.js";
import { getDownloadURL } from "firebase/storage";
import i18n from "@/plugins/i18n";
import { mapActions } from "vuex";

export default {
  name: "VideoUsageGuide",
  mixins: [firebaseTools],
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: undefined,
      loading: false
    };
  },
  methods: {
    ...mapActions(["setShowSnackBar"]),
    closePopUp() {
      this.$emit("hideVideoPopUp");
    },
    setUrlVideo() {
      this.loading = true;
      const path = `documentation/${this.config.file}.mp4`;
      const videoRef = this.createStorageReference(path);
      getDownloadURL(videoRef)
        .then((url) => {
          this.url = url;
        })
        .catch(() => {
          this.setShowSnackBar({
            color: 'error',
            msg: i18n.t('errorLoadVideo'),
            icon: "mdi-alert-circle",
          })
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.setUrlVideo();
  },
};
</script>
<style lang="scss" scoped>
.video-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  font-size: 20px;
  justify-content: center;
  border-radius: 10px;
  align-items: center;

  &__button {
    width: 100%;
    font-size: 14px;
    .mdi:before {
      color: var(--darkGray);
      font-size: 18px;
    }
  }
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  box-shadow: unset;
  background-color: var(--bgSecondary);
}
</style>
